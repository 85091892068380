import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule} from '../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QuillModule } from 'ngx-quill';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SampleRoutingModule } from './dialog.routing';
import { DialogComponent } from './dialog/dialog.component';

@NgModule({
imports: [
CommonModule,
DemoMaterialModule,
FlexLayoutModule,
QuillModule,
FormsModule,
ReactiveFormsModule
// SampleRoutingModule
],
exports:[DialogComponent],
providers:[],
declarations: [DialogComponent]
})
export class ConfirmModule { }
